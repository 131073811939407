import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "technical-description"
    }}>{`Technical description`}</h1>
    <h2 {...{
      "id": "access-to-the-services"
    }}>{`Access to the Services`}</h2>
    <p>{`NOD has two interfaces: A back-end system for PTOs as of today can be accessed through WebServices, and one client-oriented REST interface that can be accessed by NOD Clients over the internet. Both interfaces are also available in a TEST and QA environment.`}</p>
    <h3 {...{
      "id": "nod-back-system"
    }}>{`NOD Back system`}</h3>
    <p>{`The baking system can only be reached via dedicated VPN tunnels which are set up against NOD's operating supplier by agreement with Entur. In addition, user names and passwords are required to reach the back office services. Use of NOD Backoffice is described in `}{`[Appendix A]`}{`. These services are defined in `}{`[HB V821.22 App D - Document provided on request.]`}{`. The following WebService services can be called:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`AddOrders`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`GetOrderGroups`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Update Order Group`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`GetOrderGroupTransactions`}</p>
      </li>
    </ul>
    <h3 {...{
      "id": "nod-client-interface"
    }}>{`NOD Client interface`}</h3>
    <p>{`The NOD client interfaces are publicly available at HTTPS://nod.ioas.no/nod/services/client/. These services are defined in `}{`[HB V821.22 App B - Document provided on request.]`}{`. The PTO must ask Entur for a password for its NOD Clients to be able to call the services.
The following REST services can be called:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Method`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`URL`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Return Values ​​`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`GET`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`/ ecard / "mediaSerialNumberID" / groups /`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URI list of`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Returns a list of URIs to active order groups`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`POST`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`/ ecard / "mediaSerialNumberID" / nodsession`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The contents of the first command set of the first available group`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The content of the first command set is returned by the NOD Client`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`POST`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`/ group / "groupId" / node session`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Contents of the first command set of the specified group Creates a NOD Session and reserves the specified group exclusively for the calling NOD Client. The content of the first command set is returned by the NOD Client`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`GET`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`/ group / "groupId" / nodsession / "nodsessionId" / cmdset / "cmdsetId"`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`list of node client commands that must be processed by the node client`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Returns the largest list of NOD Client Commands that may be executed without the need for the server to process the results first`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`POST`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`/ group / "groupId" / nodsession / "nodsessionId" / cmdset / "cmdsetId"`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The URL of the next command set, as well as its contents.`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Creates a new command set based on the result of the returned commands.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "plugins"
    }}>{`Plugins`}</h3>
    <p>{`A Plugin is an independent WEB application that exposes a single REST interface to the NOD platform. See `}{`[HB V821.22 App C - Document provided on request.]`}{` for details. Here, developers are free to choose technology and platform themselves. NOD uses a Plugin by sending Image and the Order to its URL, in other words, a Plugin can be hosted outside the actual NOD platform, for example in an existing switch environment that the PTO has. This assumes that a secure communication channel is established between the NOD Platform and the Plugin. If a Plugin is to run on the NOD platform itself, it must be able to run on Apache Tomcat.
If a Plugin deployes on the NOD Platform, it will also get the load balancing and failover properties that the NOD Platform offers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      